<!--
--------------------------------------------------------------------------------
<copyright file="CameraView.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <summary>{{ $t('widget_info_text.camera_view') }}</summary>
    <img src="images/info/cameraView.jpg" />
    <p class="text">{{ $t('widget_info_text.camera_view_txt') }}</p>
    <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
    <p class="text info-bkg">{{ $t('widget_info_text.camera_view_info_txt') }}</p>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class CameraViewInfo extends Vue {}
</script>
